<template>
  <div :class="'cs-menu-style-'+this.menuComponent">
    <component :is="menuComponent" :data="data" /> 
  </div>
</template>

<script>

function getCookie(c_name) {
    try{
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return unescape(y);
            }
        } 
        return 'no'
    }catch(e){
        console.log(e);
        return 'no'
    }
}

  export default {
    name: "CSMenu",
    components: {
      CSMenuLeft: () => import('./Menus/CSMenuLeft.vue'),
      CSMenuRight: () => import('./Menus/CSMenuRight.vue'),
      CSMenuJustify: () => import('./Menus/CSMenuJustify.vue'),
      CSMenuCenterDouble: () => import ('./Menus/CSMenuCenterDouble.vue'),
      CSMenuOverlay: () => import('./Menus/CSMenuOverlay.vue')
    },
    props: {
      logo: {
        type:String,
        default:''
      },
      links: {
        type:[Object, Array]
      },
      menuStyle: {
        type:String,
        default:'left'
      },
      menu: {
        type: [Object, Array],
        default: () => {},
      },
    },
    data() {
      return {
        menus: {
          left: 'CSMenuLeft',
          right: 'CSMenuRight',
          justify: 'CSMenuJustify',
          centerDouble: 'CSMenuCenterDouble',
          overlay: 'CSMenuOverlay'
        },
        isLoggedIn:false
      }
    },
    computed: {
      data(){
        return {
          logo:this.logo,
          links:this.links,
          style:this.menuStyle,
          menu:this.menu,
          isLoggedIn:this.isLoggedIn
        }
      },  
      menuComponent() {
        if (this.menuStyle && this.menus[this.menuStyle]) return this.menus[this.menuStyle];
        return 'CSMenuOverlay';
      }
    },
    mounted(){
      let login = getCookie('login')
      if(login == 'yes') this.isLoggedIn = true;
      else this.isLoggedIn = false
      
    }
  };
</script>

<style lang="scss" scoped>
  .cs-menu-style{
    &-CSMenuCenterDouble {
      height: 159px;

      ::v-deep {
        .cs-nav-primary {
          min-height: 159px;
        }
      }
    }
    &-CSMenuJustify {
      height: 107px;

      ::v-deep {
        .cs-nav-primary {
          min-height: 107px;
        }
      }
    }
    &-CSMenuLeft {
      height: 107px;

      ::v-deep {
        .cs-nav-primary {
          min-height: 107px;
        }
      }
    }
    &-CSMenuOverlay {
      height: 107px;

      ::v-deep {
        .cs-nav-primary {
          min-height: 107px;
        }
      }
    }
    &-CSMenuRight {
      height: 107px;

      ::v-deep {
        .cs-nav-primary {
          min-height: 107px;
        }
      }
    }
  }


  ::v-deep {
    span * img {
      max-width: 100%;
      max-height: 300px;
      margin: 10px 0;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }

    .cs-nav-primary > a,
    .cs-nav-primary-links > span,
    .cs-nav-primary-links > a {
      align-items: center;

      @media (min-width: 1200px) {
        height: 92px;
      }
    }

    .cs-nav-primary-links {
      @media (max-width: 1199.98px) {
        span,
        a {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .mobile-dropdown {
            align-items: center;
          }
        }

        .single-dropdown {
          display: none; /* It just was decided to just not display it and display another one */
        }
      }
    }
  }
</style>